import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Drawer, Form, Button, Input, Checkbox, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import Message from "../../../components/message";

import { REQUESTS } from "../../../api/requests";

import TEXTS from "../../../config/texts";

import classes from "../style/playListDrawer.module.scss";

export default function PlaylistDrawer({
  onClose,
  visible,
  current,
  deviceId,
  getPlaylist,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const translation = useSelector((state) => state.globalState.translation);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const [isProtected, setIsProtected] = useState(false);

  const [server, setServer] = useState([]);
  const [selected, setSelected] = useState("custom");

  const [form] = Form.useForm();

  const onFinish = (values) => {

    const { name, url, username, password, server_id } = values;

    setLoading(true);

    const body = {
      name: name,
      deviceId,
      is_protected: isProtected,
      url,
    };

    if (isProtected) {
      body["pin"] = values.pin;
      body["confirm_pin"] = values.confirm_pin;
    }

    if (selected !== "custom") {
      let selectedServer = server?.find((item) => item.id === selected);

      if (selectedServer && username && password) {
        const url = `${selectedServer.host}/get.php?username=${username.trim()}&password=${password.trim()}`

        body["url"] = url
      }
    }

    function callback(response) {
      setLoading(false);
      onClose();
      getPlaylist();
    }

    function errorCallback(error) {
      setLoading(false);
      setMessage({
        text: error,
        type: false,
      });
    }

    if (current) {
      body.id = current.id;
      REQUESTS.PLAYLIST.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.PLAYLIST.ADD(body, callback, errorCallback);
    }

  };

  const onSubmit = () => {
    setMessage({
      text: "",
      type: true,
    });
    form.submit();
  };

  const onInputEnter = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const getServer = () => {
    function callback(data) {
      setServer(data.rows)
    }

    REQUESTS.SERVER_GET(callback);
  }

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setIsProtected(false)
      setMessage({
        text: "",
        type: true,
      });
      setSelected("custom")
    } else {
      getServer()
    }
  }, [visible]);

  useEffect(() => {
    if (current && visible) {
      form.setFields([
        { name: "name", value: current.name },
        { name: "url", value: current.url },
      ]);
      setIsProtected(current.is_protected);
    }
  }, [current, visible]);

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <Drawer
      width={windowWidth > 600 ? 600 : "75vw"}
      placement="right"
      title={
        current
          ? translation["Edit playlist"] || TEXTS["Edit playlist"]
          : translation["Add playlist"] || TEXTS["Add playlist"]
      }
      onClose={onClose}
      open={visible}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        name="add-playlist"
      >

        {
          server && server.length > 0 && <Form.Item
            name="server_id"
            label={translation["DNS"] || TEXTS["DNS"]}
          >
            <Select
              className={classes["select"]}
              onChange={(value) => {
                setSelected(value);
                form.resetFields();
              }}
              defaultValue={selected}
            >
              <Select.Option value={"custom"}>Custom</Select.Option>
              {
                server?.map((item, index) => {
                  return <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                })
              }
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="name"
          label={translation["Playlist name"] || TEXTS["Playlist name"]}
          rules={[
            {
              required: true,
              message: translation["This field is required!"] || TEXTS["This field is required!"]
            },
          ]}
        >
          <Input onKeyDown={onInputEnter} />
        </Form.Item>

        {
          selected === "custom" ? <Form.Item
            name="url"
            label={translation["Playlist url"] || TEXTS["Playlist url"]}
            rules={[
              {
                required: true,
                message: translation["This field is required!"] || TEXTS["This field is required!"],
              },
            ]}
          >
            <Input onKeyDown={onInputEnter} />
          </Form.Item>
            : <>
              <Form.Item
                label={translation["Username"] || TEXTS["Username"]}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input username",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={translation["Password"] || TEXTS["Password"]}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
        }
        <Checkbox
          checked={isProtected}
          onChange={(e) => setIsProtected(e.target.checked)}
          className={classes['protected']}
        >
          {translation["Protect Playlist"] || TEXTS["Protect Playlist"]}
        </Checkbox>


        <Form.Item
          name="pin"
          label={translation["Set PIN"] || TEXTS["Set PIN"]}
          rules={[
            {
              required: isProtected ? true : false,
              message: translation["This field is required!"] || TEXTS["This field is required!"],
            },
          ]}
          hasFeedback
        >
          <Input type="password" onKeyDown={onInputEnter} disabled={current && current.is_protected ? current.is_protected : !isProtected} />
        </Form.Item>

        <Form.Item
          name="confirm_pin"
          label={translation["Confirm PIN"] || TEXTS["Confirm PIN"]}
          dependencies={["pin"]}
          hasFeedback
          rules={[
            {
              required: isProtected ? true : false,
              message: translation["Confirm password"] || TEXTS["Confirm password"],
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    translation["The two passwords that you entered do not match!"] || TEXTS["The two passwords that you entered do not match!"]
                  )
                );
              },
            }),
          ]}
        >
          <Input type="password" onKeyDown={onInputEnter} disabled={current && current.is_protected ? current.is_protected : !isProtected} />
        </Form.Item>

        <Message message={message} />

        <Form.Item className={classes["current-device-button"]}>
          <Button
            type="primary"
            loading={loading}
            onClick={onSubmit}
            icon={<CheckOutlined />}
          >
            {translation["Save"] || TEXTS["Save"]}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
